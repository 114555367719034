import {
	ISSUER_FIRSTNAME_BAJAJ,
	ISSUER_FIRSTNAME_MAHINDRA,
	ISSUER_FIRSTNAME_SHRIRAM,
	ISSUER_FIRSTNAME_UNITY,
} from './constants'

export const HOME_PAGE = 'home'
export const ABOUT_PAGE = 'about'
export const SUPPORT_PAGE = 'support'

export function getFdImageFromName(fdName: string) {
	switch (fdName) {
		case ISSUER_FIRSTNAME_BAJAJ:
			return 'https://bhihtsmosmghzjnmhufc.supabase.co/storage/v1/object/public/superfd-images/website/bajaj.png'
		case ISSUER_FIRSTNAME_SHRIRAM:
			return 'https://bhihtsmosmghzjnmhufc.supabase.co/storage/v1/object/public/superfd-images/website/shriram.png'
		case ISSUER_FIRSTNAME_MAHINDRA:
			return 'https://bhihtsmosmghzjnmhufc.supabase.co/storage/v1/object/public/superfd-images/website/mahindra.png'
		case ISSUER_FIRSTNAME_UNITY:
			return 'https://bhihtsmosmghzjnmhufc.supabase.co/storage/v1/object/public/superfd-images/website/unity.png'
		case SUPPORT_PAGE:
			return 'https://bhihtsmosmghzjnmhufc.supabase.co/storage/v1/object/public/superfd-images/website/support.png'
		default:
			return 'https://bhihtsmosmghzjnmhufc.supabase.co/storage/v1/object/public/superfd-images/website/superfd-home.png'
	}
}

export function getSocialMetas({
	url,
	title = 'SuperFD',
	description = 'Earn 9.5% p.a. interest rate on your next FD',
	image = getFdImageFromName('home'),
	keywords = '',
}: {
	image?: string
	url: string
	title?: string
	description?: string
	keywords?: string
}) {
	return [
		{ title },
		{ name: 'description', content: description },
		{ name: 'keywords', content: keywords },
		{ name: 'image', content: image },
		{ name: 'og:url', content: url },
		{ name: 'og:title', content: title },
		{ name: 'og:description', content: description },
		{ name: 'og:image', content: image },
		{
			name: 'twitter:card',
			content: image ? 'summary_large_image' : 'summary',
		},
		{ name: 'twitter:creator', content: '@superfd' },
		{ name: 'twitter:site', content: '@superfd' },
		{ name: 'twitter:title', content: title },
		{ name: 'twitter:description', content: description },
		{ name: 'twitter:image', content: image },
		{ name: 'twitter:image:alt', content: title },
	]
}

export function getFdTitleFromName(fdName: string, fdId: string | undefined) {
	switch (fdName) {
		case ISSUER_FIRSTNAME_BAJAJ:
			return {
				title: 'Bajaj Finance Limited | Super FD',
				description:
					'Bajaj Finance FD Interest Rates offers upto 8.25% p.a. for regular & upto 8.5% for Senior Citizens. Check Bajaj Finance Fixed deposit (FD) Rates 2024 & Invest.',
				url: `https://www.superfd.in/fd/${fdId}`,
				image: getFdImageFromName(fdName),
				keywords:
					'Bajaj fd, Bajaj online fd, Bajaj fd rates, Bajaj fixed deposit, Bajaj Highest Interest rate, Bajaj online, Bajaj finance, Bajaj fd booking, Bajaj calculator',
			}
		case ISSUER_FIRSTNAME_SHRIRAM:
			return {
				title: 'Shriram Finance | Super FD',
				description:
					'Shriram Finance Fixed Deposit (FD) offers the highest interest rate, up to 9.07* p.a., inclusive of 0.50%* p.a. for senior citizens & 0.10%* p.a. for Women depositors.',
				url: `https://www.superfd.in/fd/${fdId}`,
				image: getFdImageFromName(fdName),
				keywords:
					'Shriram fd, Shriram online fd, Shriram fd rates, Shriram fixed deposit, Shriram Highest Interest rate, Shriram online, Shriram finance, Shriram fd booking, Shriram calculator',
			}
		case ISSUER_FIRSTNAME_MAHINDRA:
			return {
				title: 'Mahindra and Mahindra Finance | Super FD',
				description:
					'Mahindra and Mahindra Finance FD Interest Rates offers upto 8.1% p.a. for regular & upto 8.35% for Senior Citizens. Check Mahindra and Mahindra Finance Fixed deposit (FD) Rates 2024 & Invest.',
				url: `https://www.superfd.in/fd/${fdId}`,
				image: getFdImageFromName(fdName),
				keywords:
					'Mahindra fd, Mahindra online fd, Mahindra fd rates, Mahindra fixed deposit, Mahindra Highest Interest rate, Mahindra online, Mahindra finance, Mahindra fd booking, Mahindra calculator',
			}
		case ISSUER_FIRSTNAME_UNITY:
			return {
				title: 'Unity SF Bank | Super FD',
				description:
					'Open Fixed Deposit Account Online (FD) with Unity Bank & get up to 9% FD interest rate for general customers & up to 9.5% for Senior Citizens.',
				url: `https://www.superfd.in/fd/${fdId}`,
				image: getFdImageFromName(fdName),
				keywords:
					'Unity fd, Unity online fd, Unity fd rates, Unity fixed deposit, Unity Highest Interest rate, Unity online, Unity finance, Unity fd booking, Unity calculator',
			}
		default:
			return {
				title: 'Super FD',
				description: 'Earn 9.5% p.a. interest rate on your next FD',
				url: `https://www.superfd.in`,
				image: getFdImageFromName('home'),
				keywords:
					'SuperFD fd, SuperFD online fd, SuperFD fd rates, SuperFD fixed deposit, SuperFD Highest Interest rate, SuperFD online, SuperFD finance, SuperFD fd booking, SuperFD calculator',
			}
	}
}

export function getMetaInfoForPages(pageName: string) {
	switch (pageName) {
		case SUPPORT_PAGE:
			return {
				title: 'Support | Super FD',
				description:
					'You can reach out to us on Email - support@superfd.in or WhatsApp - +91 9972749625',
				url: `https://www.superfd.in/support`,
				image: getFdImageFromName(pageName),
				keywords:
					'superfd contact, superfd customer support, superfd call, superfd email, superfd whatsapp , superfd customer care, fd contact, fd online support',
			}
		case ABOUT_PAGE:
			return {
				title: 'About Us | Super FD',
				description:
					'Super FD is an Investment Platform focusing only on Fixed Return products, Our team of Investment Experts curate the Best Fixed Return products based on several factors like Issuer’s Credibility, Interest rate and Yield.',
				url: `https://www.superfd.in/about`,
				image: getFdImageFromName('home'),
				keywords:
					'superfd about us, about superfd, superfd info, superfd company info, superfd company details, superfd more info',
			}
		case HOME_PAGE:
			return {
				title: 'Home | Super FD',
				description:
					'Earn 9.5% p.a. interest rate on your next FD.Super FD is an Investment Platform focusing only on Fixed Return products, Our team of Investment Experts curate the Best Fixed Return products based on several factors like Issuer’s Credibility, Interest rate and Yield.',
				url: `https://www.superfd.in`,
				image: getFdImageFromName('home'),
				keywords:
					'Book FD, Fixed Deposit, Highest Interest rate FD, online fd, sbi fd, sbi fixed deposit, hdfc fd, hdfc fixed deposit, book fd online',
			}
		default:
			return {
				title: 'Super FD',
				description: 'Earn 9.5% p.a. interest rate on your next FD',
				url: `https://www.superfd.in`,
				image: getFdImageFromName('home'),
				keywords:
					'SuperFD fd, SuperFD online fd, SuperFD fd rates, SuperFD fixed deposit, SuperFD Highest Interest rate, SuperFD online, SuperFD finance, SuperFD fd booking, SuperFD calculator',
			}
	}
}
